export const errorFunctions = [
    missingMinSub,
    missingMin,
    missingSub,
    wrongSymbols,
    switchedMinSub,
    confusedContextMinSub,
    confusedContextMin,
    confusedContextSub,
    wrongMinSub,
    wrongMin,
    wrongSub,
]

// Syntax errors
function wrongSymbols(min, sub, solutionMin, solutionSub, context) {
    let numregex = /^\d+$/;
    if (!numregex.test(min) || !numregex.test(sub)) {
        return { errorType: "wrongSymbols", errorMessage: "Bitte gib nur Zahlen ein." };
    }
    return null;
}

function missingMinSub(min, sub, solutionMin, solutionSub, context) {
    if (min === '' && sub === '') {
        return { errorType: "missing", errorMessage: "Bitte trage zunächst den Minuenden und Subtrahenden ein!" };
    }
    return null;
}

function missingMin(min, sub, solutionMin, solutionSub, context) {
    if (min === '' && sub !== '') {
        return { errorType: "missing", errorMessage: "Bitte trage noch die obere Zahl (den Minuenden) ein!" };
    }
    return null;
}

function missingSub(min, sub, solutionMin, solutionSub, context) {
    if (min !== '' && sub === '') {
        return { errorType: "missing", errorMessage: "Bitte trage noch die untere Zahl (den Subtrahenden) ein!" };
    }
    return null;
}

// Semantic errors
function wrongMinSub(min, sub, solutionMin, solutionSub, context) {
    if (Number(min) !== solutionMin || Number(sub) !== solutionSub) {
        return { errorType: "wrong", errorMessage: "Überprüfe nochmal, ob beide Zahlen richtig sind!" };
    }
    return null;
}

function wrongMin(min, sub, solutionMin, solutionSub, context) {
    if (Number(min) !== solutionMin && Number(sub) === solutionSub) {
        return { errorType: "wrong", errorMessage: "Überprüfe nochmal, ob der Minuend richtig ist!" };
    }
    return null;
}

function wrongSub(min, sub, solutionMin, solutionSub, context) {
    if (Number(min) === solutionMin && Number(sub) !== solutionSub) {
        return { errorType: "wrong", errorMessage: "Überprüfe nochmal, ob der Subtrahend richtig ist!" };
    }
    return null;
}

// Context errors
function switchedMinSub(min, sub, solutionMin, solutionSub, context) {
    if (Number(min) === solutionSub && Number(sub) === solutionMin) {
        return { errorType: "switch", errorMessage: "Es sieht so aus als hättest du Minuend und Subtrahend miteinander vertauscht!" };
    }
    return null;
}

function confusedContextMinSub(min, sub, solutionMin, solutionSub, context) {
    if (context.includes(Number(min)) && context.includes(Number(sub))) {
        return { errorType: "context", errorMessage: "Überprüfe nochmal, ob du die richtigen Zahlen aus dem Text extrahiert hast!" };
    }
    return null;
}

function confusedContextMin(min, sub, solutionMin, solutionSub, context) {
    if (context.includes(Number(min)) && !context.includes(Number(sub))) {
        return { errorType: "context", errorMessage: "Überprüfe nochmal, ob du den richtigen Minuend aus dem Text extrahiert hast!" };
    }
    return null;
}

function confusedContextSub(min, sub, solutionMin, solutionSub, context) {
    if (!context.includes(Number(min)) && context.includes(Number(sub))) {
        return { errorType: "context", errorMessage: "Überprüfe nochmal, ob du den richtigen Subtrahend aus dem Text extrahiert hast!" };
    }
    return null;
}
