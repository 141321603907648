import React from "react";
import PropTypes from "prop-types";
import '../../CSS/TextExercise.css';
import { withRouter } from "react-router-dom";
import CloseSpeechbubble from "../../shared/Speechbubble/CloseSpeechbubble.js";
import TextExercises from "../../resources/TextExercises.json";
import { errorFunctions } from "./TextExerciseErrorLibrary.js";
import { initializeExerciseNumbers, generateTextExercise, randomIndexFromList} from "./TextExerciseUtil.js"


/**
 * @author: Mai Anh Vu
 * @date: 2024-08-12
 */
class InputTextNumbers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showError: false,
      submitButtonText: 'Überprüfen',
      submitButtonOnClick: this.validateNumbers,
      isSubmitted: false
    };
    
    const { solutionMin, solutionSub, contextNumbers } = initializeExerciseNumbers(3);
    
    this.solutionMin = solutionMin;
    this.solutionSub = solutionSub;
    this.contextNumbers = contextNumbers;
    this.submitText = null;
    this.textExercisesIndex = randomIndexFromList(TextExercises.exercisesEasy);
    this.text = generateTextExercise(this.solutionMin, this.solutionSub, this.contextNumbers, TextExercises.exercisesEasy, this.textExercisesIndex);
  }

  validateNumbers = () => {
    var minuend = document.getElementById("obereZahl").value;
    var subtrahend = document.getElementById("untereZahl").value;

      for (const errorFunction of errorFunctions) { 
          const error = errorFunction(minuend, subtrahend, this.solutionMin, this.solutionSub, this.contextNumbers) ?? { errorType: null, errorMessage: null };
          if (error.errorMessage) {
            this.setState({
              showError: true,
            });
            this.props.changeSize();
            this.props.showAnalogy(this.textExercisesIndex, error.errorType, error.errorMessage);
            return;
          }
      }
      this.submitText = "Super! Jetzt kannst du die Aufgabe lösen.";
      this.setState({
        showError: true,
        submitButtonText: 'Los geht\'s',
        submitButtonOnClick: () => this.props.submit(minuend, subtrahend),
        isSubmitted: true
      });
  }

  handleChangeSize = (event) => {
    const newSize = event.target.value;
    this.props.changeSize(newSize); // Pass the new size up to the parent
  };
 
  render() {
   
    let submitText = [];
    if (this.state.showError)
      submitText = <p className="errorText">{this.submitText}</p>;

    return (
      <div className="inputTextEx">
        <div className="inputOwnExBubble">
          <div className="ownExText">{this.text}</div>
          <div className="obereZahlText">Obere Zahl:</div>

          <input
            maxLength="5" //changed
            max="99999"  //changed
            className="obereZahlInput"
            id="obereZahl"
            disabled={this.state.isSubmitted}
          ></input>

          <div className="untereZahlText">Untere Zahl:</div>

          <input
            maxLength="5" //changed
            max="99999" //changed
            className="untereZahlInput"
            id="untereZahl"
            disabled={this.state.isSubmitted}
          ></input>

          {submitText}

          <button
            className="submitButton"
            onClick={this.state.submitButtonOnClick}
          >
            {this.state.submitButtonText}
          </button>
        </div>
        <CloseSpeechbubble close_func={this.props.close_func} />
      </div>
    );
  }
}

InputTextNumbers.propTypes = {
  submit: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
  close_func: PropTypes.func,
  changeSize: PropTypes.func.isRequired,
  showAnalogy: PropTypes.func.isRequired,
};

export default withRouter(InputTextNumbers);
