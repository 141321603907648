import TextExercises from "../../resources/TextExercises.json";

export const initializeExerciseNumbers = (digits) => {
    const numbers = assignNumbers(digits);
    const solutionMin = numbers[0];
    const solutionSub = numbers[1];
    const contextNumbers = assignNumbers(2);

    return { solutionMin, solutionSub, contextNumbers };
};

export const getRandomNumber = (digits) => {
    const min = Math.pow(10, digits - 1);
    const max = Math.pow(10, digits) - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const randomIndexFromList = (list) => {
    const min = 0;
    const max = list.length - 1;
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomName = (nameList) => {
    return nameList[randomIndexFromList(nameList)];
};

export const assignNumbers = (digits) => {
    let number1 = getRandomNumber(digits);  
    let number2 = getRandomNumber(digits);  
    let numHigh;
    let numLow;

    if (number1 > number2) {
        numHigh = number1;
        numLow = number2;
    } else {
        numHigh = number2;
        numLow = number1;
    }

    return [numHigh, numLow];
};

export const generateTextExercise = (solutionMin, solutionSub, context, textExercises, textExercisesIndex) => {
    return assignEntries(textExercises[textExercisesIndex], solutionMin, solutionSub, context, null);
};

export const assignEntries = (list, solutionMin, solutionSub, context, errorMessage, analogyExerciseExample) => {
    const name1 = getRandomName(TextExercises.name1);
    const name2 = getRandomName(TextExercises.name2);

    const mapItem = (item) => {
        switch (item) {
            case "[name1]": return name1;
            case "[name2]": return name2;
            case "[numHigh]": return solutionMin;
            case "[numLow]": return solutionSub;
            case "[context1]": return context[0];
            case "[context2]": return context[1];
            case "[errorMessage]": return errorMessage;
            case "[textExercise]": return analogyExerciseExample + "\n\n";
            default: return item;
        }
    };

    return list.map(mapItem).join('');
};


