import React from 'react';
import PropTypes from 'prop-types';
import '../../CSS/TextExercise.css';
import SpeechbubbleControlls from '../../shared/Speechbubble/SpeechbubbleControls.js';
import TextExercises from "../../resources/TextExercises.json";
import TextExerciseAnalogies from "../../resources/TextExerciseAnalogies.json";
import { initializeExerciseNumbers, generateTextExercise, assignEntries } from "./TextExerciseUtil.js"

/**
 * @author: Mai Anh Vu
 * @date: 2024-08-12
 */
class TextExerciseAnalogy extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      analogyTextIndex: 0,
      analogy: []
    };
    const { solutionMin, solutionSub, contextNumbers } = initializeExerciseNumbers(3);

    this.solutionMin = solutionMin;
    this.solutionSub = solutionSub;
    this.contextNumbers = contextNumbers;
    this.analogyExerciseExample = generateTextExercise(this.solutionMin, this.solutionSub, this.contextNumbers, TextExercises.exercisesEasy, this.props.textExercisesIndex);
  }

  generateAnalogy = () => {
    let analogy = [...TextExerciseAnalogies.analogyStart];

    if (this.props.errorType === 'none') {
      return;
    }

    for (const entry of TextExerciseAnalogies.errors[this.props.errorType]) {
      if (Array.isArray(entry)) {
        analogy.push(this.assignEntries(entry))
      } else if (entry === "[analogyExercise]") {
        for (const analogyExercise of TextExerciseAnalogies.analogyExercise) {
          analogy.push(this.assignEntries(Array.isArray(analogyExercise) ? analogyExercise : [analogyExercise]));
        }
      } else {
        analogy.push(this.assignEntries([entry]))
      }
    }

    analogy.push(TextExerciseAnalogies.analogyEnd.join(''));
    return analogy;
  }

  componentDidMount() {
    // Initialize analogy on mount
    const analogy = this.generateAnalogy();
    this.setState({ analogy, analogyTextIndex: 0 });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errorType !== this.props.errorType) {
      // Regenerate analogy when errorType prop changes
      const analogy = this.generateAnalogy();
      this.setState({ analogy, analogyTextIndex: 0 }); // Reset index to start of new analogy
    }
  }

  assignEntries = (entry) => {
    return assignEntries(entry, this.solutionMin, this.solutionSub, this.contextNumbers, this.props.errorMessage, this.analogyExerciseExample);
  }

  nextText = () => {
    this.setState({
      analogyTextIndex: this.state.analogyTextIndex + 1,
    });
  }

  lastText = () => {
    if (this.state.analogyTextIndex === 0) {
      return;
    }
    this.setState({
      analogyTextIndex: this.state.analogyTextIndex - 1,
    });
  }

  render() {
    const { analogy, analogyTextIndex } = this.state;
    return (
      <div className="textExerciseAnalogy">
        <SpeechbubbleControlls
          text={analogy[analogyTextIndex]}
          nextText={this.nextText}
          lastText={this.lastText}
          beginning={analogyTextIndex === 0}
          end={analogyTextIndex === analogy.length - 1}
          analogy={false}
          subpanel_visibility={"hidden"}
          close_func={this.props.close_func}
        />
      </div>
    );
  }
}

TextExerciseAnalogy.propTypes = {
  textExercisesIndex: PropTypes.number.isRequired,
  errorType: PropTypes.string.isRequired,
  errorMessage: PropTypes.string.isRequired,
  close_func: PropTypes.func,
};

export default TextExerciseAnalogy;