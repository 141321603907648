import React from 'react';
import { withRouter } from 'react-router-dom';
import InputTextNumbers from './InputTextNumbers.js';
import TextExerciseAnalogy from './TextExerciseAnalogy.js';
import Subkraki from '../../shared/Subkraki.js';
import '../../CSS/TextExercise.css';
import PropTypes from 'prop-types';

/**
 * @author: Mai Anh Vu
 * @date: 2024-08-12
 */
class TextExercise extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      subkrakiSize: '',
      showAnalogy: false,
      textExercisesIndex: '',
      errorType: '',
      errorMessage: ''
    };
  }
  /**
   * Gets minuend and subtrahend from InputOwnNumbers.js and
   * submits it to Calculate.js
   * @param {int} minuend   Minuend of the own exercise
   * @param {int} subtrahend    Subtrahend of the own exercise
   */

  returnExercise(minuend, subtrahend) {
    this.props.returnEx(minuend, subtrahend);
  }

  changeSubkrakiSizeSmall = () => {
    this.setState({ subkrakiSize: 'small' });
  };

  showAnalogy = (textExercisesIndex, errorType, errorMessage) => {
    this.setState({
      showAnalogy: true,
      textExercisesIndex,
      errorType,
      errorMessage
    });
  }

  hideAnalogy = () => {
    this.setState({ 
      showAnalogy: false,
      subkrakiSize: ''
     });
  }

  render() {

    return (
      <div className="textEx">
        <InputTextNumbers
          submit={(min, sub) => this.returnExercise(min, sub)}
          close_func={this.props.close_func}
          changeSize={this.changeSubkrakiSizeSmall}
          showAnalogy={this.showAnalogy}
        />
        {this.state.showAnalogy && (
          <TextExerciseAnalogy
            close_func={this.hideAnalogy}
            textExercisesIndex={this.state.textExercisesIndex}
            errorType={this.state.errorType}
            errorMessage={this.state.errorMessage}
          />
        )}
        <Subkraki size={this.state.subkrakiSize} />
      </div>
    );
  }
}

TextExercise.propTypes = {
  returnEx: PropTypes.func,
  close_func: PropTypes.func,
};
export default withRouter(TextExercise);
